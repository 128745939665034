<div #root class="currency-select-root relative">
  <button (click)="showOptions = !showOptions" class="dropdown-fiat-currency-button"
    class="flex-shrink-0 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white border-4 border-pink-500/30 rounded-full hover:bg-pink-500/20 focus:outline-none focus:border-pink-500"
    type="button">
    <cs-flag [code]="selectedOption.countryCode" class="me-2 h-5"></cs-flag>
    {{selectedOption.text}}
    <ng-icon name="heroChevronDownMini" size="1.5em" class="ms-2.5"></ng-icon>
  </button>
  <div [ngClass]="{ 'hidden': !showOptions }"
    class="dropdown-fiat-currency absolute top-12 left-0 z-1000 bg-black/90 divide-y divide-black rounded-lg shadow w-72 overflow-auto scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-pink-950 scrollbar-thumb:!rounded scrollbar-thumb:!bg-pink-500 scrollbar-track:!rounded max-h-96 lg:supports-scrollbars:pr-2">
    <ul class="py-2 text-sm text-white" aria-labelledby="dropdown-fiat-currency-button">
      <li *ngFor="let opt of options">
        <button type="button" (click)="value.set(opt.countryCode); showOptions = false"
          [class.bold]="opt === selectedOption" [class.bg-pink-950]="opt === selectedOption"
          class="inline-flex w-full px-4 py-2 text-sm text-white hover:bg-pink-950 focus:bg-pink-950 focus:ring-pink-500"
          role="menuitem">
          <div class="inline-flex items-center">
            <cs-flag code="{{ opt.countryCode }}" class="me-2 h-5"></cs-flag>
            {{ opt.text }}
          </div>
        </button>
      </li>
    </ul>
  </div>
</div>
