export interface ICryptoCurrency {
  id: string;
  symbol: string;
  alias?: string;
  name: string;
  addresses: ICryptoAddresses;
}

export interface ICryptoAddresses {
  default?: string;
  ethereum?: string;
  tag?: string;
  memo?: string;
}

export default <ICryptoCurrency[]>[
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin",
    addresses: {
      default: "15zAB3RHUpL72uefQHHiAFBsLkFMFggCL5"
    }
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ethereum",
    addresses: {
      default: "0x5fBa3e5B2b0f8cabf3192404D6Fc4C9690ad68aC"
    }
  },
  {
    id: "tether",
    symbol: "USDTERC20",
    alias: "USDT",
    name: "Tether",
    addresses: {
      ethereum: "0x25bDc7c5F5894414A4Da8549065a3DDf4081D8F3",
    }
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana",
    addresses: {
      default: "HdauJKgtbQDAvnBrS1eqMcB98d2wXW3n4QHTYKcJJzPn"
    }
  },
  {
    id: "ripple",
    symbol: "XRP",
    name: "XRP",
    addresses: {
      default: "rw2ciyaNshpHe7bCHo4bRWq6pqqynnWKQg", tag: "35743486"
    }
  },
  {
    id: "usd-coin",
    symbol: "USDC",
    name: "USDC",
    addresses: {
      ethereum: "0x3AE477E59f9495B11b3eCdcFcED0a820231DFbFA",
    }
  },
  {
    id: "dogecoin",
    symbol: "DOGE",
    name: "Dogecoin",
    addresses: {
      default: "DP5mNuTrkPuiEvCw3jJZdH1vDGwh3zTh7y"
    }
  },
  {
    id: "cardano",
    symbol: "ADA",
    name: "Cardano",
    addresses: {
      default: "addr1vywppmk3l78jzrne6ef8lakxmtnprdv6eek2a60r9gv8qfg5s8t45"
    }
  },
  {
    id: "avalanche-2",
    symbol: "AVAX",
    name: "Avalanche",
    addresses: {
      default: '0xc3eE6378d70EdB2725D61f6E86cfcDD0B6440337'
    }
  },
  {
    id: "shiba-inu",
    symbol: "SHIB",
    name: "Shiba Inu",
    addresses: {
      ethereum: "0x62358035266A7a6543FD786197268D363F0A0f0d"
    }
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot",
    addresses: {
      default: "11uCsqXCaCv1r4fXBEmFuwSuZfx3Zwr8vUbNqesHPKaBBjr"
    }
  },
  {
    id: "bitcoin-cash",
    symbol: "BCH",
    name: "Bitcoin Cash",
    addresses: {
      default: "qzag33sjhs356p3u4amm67jua52wk2arycg2glh45d"
    }
  },
  {
    id: "chainlink",
    symbol: "LINK",
    name: "Chainlink",
    addresses: {
      ethereum: "0x99fEa454cdDf86ea33E786bD1c20c2703CE3d743",
    }
  },
  {
    id: "uniswap",
    symbol: "UNI",
    name: "Uniswap",
    addresses: {
      ethereum: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    }
  },
  {
    id: "matic-network",
    symbol: "MATIC",
    name: "Polygon",
    addresses: {
      ethereum: "0x2EE9ca1FA502804Ca2dcCdA0AfE23232E9D135dd",
    }
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "Litecoin",
    addresses: {
      default: "LKRN4ou4xTGNnwVjM5Ld93Q1C2QH2ftLvr"
    }
  },
  {
    id: "ethereum-classic",
    symbol: "ETC",
    name: "Ethereum Classic",
    addresses: {
      default: "0x829778b05212c397c0E38DBdd3F2C32b6bb1245E"
    }
  },
  {
    id: "dai",
    symbol: "DAI",
    name: "Dai",
    addresses: {
      ethereum: "0x71c85A04f3051fa8a3E867052C1770997318b2AB",
    }
  },
  {
    id: "stellar",
    symbol: "XLM",
    name: "Stellar",
    addresses: {
      default: "GBOYDKMW7MKSXV3UAPTEWVF3IX2EIJ4YOCEH6MOO5XTYOJKIH73YESVB", memo: "1766480392"
    }
  },
]
