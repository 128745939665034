import { Injectable } from '@angular/core';
import { ProductClient, ProductData } from './api.services';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  // Cached product data
  public data$ = new BehaviorSubject<ProductData | undefined>(undefined);

  constructor(private productClient: ProductClient) {
    this.fetchProducts();
  }

  // Fetch products from the API
  fetchProducts() {
    this.productClient.list().subscribe(response => {
      if (response.products) {
        this.data$.next(response.products);
      }
    });
    return this.data$;
  }
}
