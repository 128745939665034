import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency',
  standalone: true
})
export class CurrencyPipe implements PipeTransform {
  transform(value: string | number, currency: string = 'USD', locale: string = 'en-US', minimumFractionDigits = 0): string | null {
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    const formattedValue = Intl.NumberFormat(locale, {
      style:'currency',
      currency
    }).format(Number(value));

    if (minimumFractionDigits == 0) {
      return formattedValue.replace(/\.00$/, '');
    }

    return formattedValue;
  }
}
