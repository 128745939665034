import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-crypto-icon',
  standalone: true,
  imports: [],
  templateUrl: './crypto-icon.component.html',
  styleUrl: './crypto-icon.component.scss'
})
export class CryptoIconComponent {
  @Input() code!: string;
}
