import { Injectable } from '@angular/core';
import { CryptoClient, GetCryptoPriceEstimatesResponse } from './api.services';
import { of, tap } from 'rxjs';
import cryptoCurrencies from '../../shared/cryptoCurrencies';

@Injectable({
  providedIn: 'root'
})
export class CryptocurrencyService {

  private cacheMaxAge = 1000 * 60 * 2; // 2 minutes
  private priceEstimateCache: { [key: string]: { timestamp: number, value: GetCryptoPriceEstimatesResponse } } = {};

  constructor(private cryptoClient: CryptoClient) { }

  getPriceEstimate(symbol: string, country: string, allowCached = true) {
    const cachedValue = this.priceEstimateCache[`${symbol}-${country}`];
    if (allowCached && cachedValue) {
      if (Date.now() - cachedValue.timestamp < this.cacheMaxAge) {
        return of(cachedValue.value);
      }
      delete this.priceEstimateCache[`${symbol}-${country}`];
    }
    return this.cryptoClient.getPriceEstimate(symbol, country).pipe(tap(response => {
      this.priceEstimateCache[`${symbol}-${country}`] = { timestamp: Date.now(), value: response };
      return response;
    }));
  }

  getName(symbol: string) {
    const symbolUpper = symbol.toUpperCase();
    return cryptoCurrencies.find(crypto => crypto.symbol.toUpperCase() === symbolUpper)?.name;
  }
}
