<div class="block">
  <nav
    class="relative z-0 flex gap-1 rounded-full bg-slate-100/0 border-4 min-w-64 border-pink-500/30"
    aria-label="Tabs" role="tablist">
    <div class="absolute rounded-full w-1/2 h-full bg-pink-500" role="tabIndicator"
      style="transition: left 0.1s ease-out;" [style.left]="value() === 'monthly' ? '50%' : '0%'"></div>
    <button
      (click)="selectCycle($event, 'yearly')"
      (keydown)="selectCycle($event, 'yearly')"
      href="#"
      class="!text-white group relative overflow-hidden min-w-0 flex-1 p-2 text-sm font-semibold text-center cursor-pointer rounded-full focus:z-10"
      role="tab" aria-current="page" style="transition: color 0.1s ease-out;"><span>Yearly Billing</span></button>
    <button
      (click)="selectCycle($event, 'monthly')"
      (keydown)="selectCycle($event, 'monthly')"
      href="#"
      class=" group relative overflow-hidden min-w-0 flex-1 p-2 text-sm font-semibold text-center cursor-pointer rounded-full focus:z-10"
      role="tab" style="transition: color 0.1s ease-out;"><span>Monthly Billing</span></button>
  </nav>
  <div class="flex mt-1 text-sm text-pink-100 text-center">
    <span class="flex-1">
      <a class="text-sm text-pink-100 hover:underline cursor-pointer" routerLink="/help/annual-discount">Save 50%</a>
    </span>
    <span class="flex-1"> </span>
  </div>
</div>
